import state from './state';
import mutations from './mutations';
import { HomeState } from './models';
import { RootState } from '@/store/models';
import { Module } from 'vuex';

export const home: Module<HomeState, RootState> = {
  namespaced: true,
  state,
  mutations
};
