import { Api, ApiInstance } from '@/api';
import Vue from 'vue';

declare module 'vue/types/vue' {
  interface Vue {
    $api: ApiInstance;
    $apiMNE: ApiInstance;
  }
}

export default (baseURL: string, mneURL: string) => ({
  install (vue: typeof Vue) {
    vue.prototype.$api = new Api({
      baseURL
    });
    vue.prototype.$apiMNE = new Api({
      baseURL: mneURL
    });
  }
});
