import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../routes/home/index.vue';
import { RouteName } from './models';

const {
  HOME,
  RESOURCE,
  ERROR_404,
  COOKIES_POLICY
} = RouteName;

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: HOME,
    component: Home
  },
  {
    path: '/zasoby/:id',
    name: RESOURCE,
    component: () => import('../routes/resources/index.vue')
  },
  {
    path: '/polityka-cookies',
    name: COOKIES_POLICY,
    component: () => import('../routes/Cookies/CookiesPolicy.vue')
  },
  {
    path: '/404',
    name: ERROR_404,
    component: () => import('../routes/404/index.vue')
  },
  {
    path: '*',
    redirect: '/404'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
});

export default router;
