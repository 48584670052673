import Vue from 'vue';
import NeVueComponents from '@ne/ne-vue2-lib';
import '@ne/ne-styles/scss/index.scss';
import { CustomOptions } from '@ne/ne-vue2-lib/src/types';
import '@ne/ne-vue2-lib/icons/ne-icons.css';
import '@ne/ne-vue2-lib/icons/ne-file-icons.css';

const neVueOptions: CustomOptions = {
  locale: 'pl'
};

Vue.use(NeVueComponents, neVueOptions);
