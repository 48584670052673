


























import Vue from 'vue';
import { NeFooter } from '@ne/ne-vue2-lib';

export default Vue.extend({
  name: 'PageFooter',
  components: {
    NeFooter
  },
  computed: {
    year () {
      return new Date().getFullYear();
    }
  }
});
