import '@/plugins/ne-vue-components';
import { getApiConfig } from '@/core/helpers/env';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import api from '@/plugins/api';
import store from './store';
import { apiPath } from '@/api/config';
import initPiwik from '@/plugins/piwik';
import initSentry from '@/plugins/sentry';

const initializeApp = async () => {
  const apiConfig = await getApiConfig();
  const apiUrl = `${apiConfig.API_URL}${apiPath}`;
  const mneURL = apiConfig.MNE_URL;
  Vue.use(api(apiUrl, mneURL));
  store.state.baseURL = apiUrl;
  store.state.mneURL = mneURL;
  initPiwik(apiConfig.PIWIK_ID);
  initSentry(apiConfig.SENTRY_DSN, apiConfig.ENV);

  new Vue({
    name: 'app',
    router,
    store,
    render: h => h(App)
  }).$mount('#app');

  Vue.config.productionTip = false;
};

initializeApp();
