import { GetterTree } from 'vuex';
import { RootState } from './models';

export const GET_API_URL = 'GET_API_URL';

const getters: GetterTree<RootState, RootState> = {
  [GET_API_URL]: state => (path: string) => {
    return state.baseURL ? `${state.baseURL}${path}` : null;
  }
};

export default getters;
