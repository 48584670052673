




































import Vue, { PropOptions } from 'vue';
import { NeSection, NeSimpleGrid, NeSimpleGridItem } from '@ne/ne-vue2-lib';
import { HompageItem } from '@/api/models/home';
import PublicationTile from '@/shared/components/PublicationTile/PublicationTile.vue';

export default Vue.extend({
  name: 'HomeContentSection',
  components: {
    NeSection,
    NeSimpleGrid,
    NeSimpleGridItem,
    PublicationTile
  },
  props: {
    title: {
      type: String,
      required: true
    } as PropOptions<string | null>,
    tiles: {
      type: Array,
      required: true
    } as PropOptions<HompageItem[]>
  },
  computed: {
    isSectionWide (): boolean {
      return (this.tiles.length > 4 || this.tiles.length % 3 === 0);
    }
  }
});
