import { MutationTree } from 'vuex';
import { HomeState } from './models';

export const SET_HOMEPAGE_DATA = 'SET_TOOLBAR_DATA';

const mutations: MutationTree<HomeState> = {
  [SET_HOMEPAGE_DATA]: (state, payload) => {
    state.data = payload.data;
  }
};

export default mutations;
