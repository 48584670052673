












import Vue from 'vue';
import { mapMutations } from 'vuex';
import { SET_HOMEPAGE_DATA } from '@/store/home/mutations';
import { HomeModel, ToolbarMenuItem } from '@/api/models/home';
import { URI_HOMEPAGE } from '@/api/endpoints';
import { NeApplication } from '@ne/ne-vue2-lib';
import Toolbar from '@/core/components/Toolbar/Toolbar.vue';
import PageFooter from '@/core/components/PageFooter/PageFooter.vue';
export default Vue.extend({
  name: 'App',
  components: {
    NeApplication,
    Toolbar,
    PageFooter
  },
  data () {
    return {
      menu: [] as ToolbarMenuItem[]
    };
  },
  methods: {
    ...mapMutations('home', {
      setHomepage: SET_HOMEPAGE_DATA
    }),
    async getHomePageData (): Promise<void> {
      const homeData: HomeModel = (await this.$api.get<HomeModel>(URI_HOMEPAGE)).data;
      this.setHomepage(homeData);
      this.menu = homeData.menu;
    }
  },
  created (): void {
    this.getHomePageData();
  }
});
