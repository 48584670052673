
















import Vue from 'vue';
import HomeContentSection from './components/HomeContentSection/HomeContentSection.vue';
import { mapState } from 'vuex';
import { HompageItem } from '@/api/models/home';

export default Vue.extend({
  name: 'Home',
  components: {
    HomeContentSection
  },
  computed: {
    ...mapState('home', ['data'])
  },
  methods: {
    getTiles (school: HompageItem): HompageItem[] {
      const tiles = [] as HompageItem[];
      const series = school && school.children ? school.children[0].children : [];
      if (series) {
        series.forEach((level) => {
          if (level.children) {
            level.children.forEach((publication) => {
              publication.title = publication.title ? publication.title : publication.name;
              publication.name = level.name;
              tiles.push(publication);
            });
          }
        });
      }
      return tiles;
    }
  }
});
