




























































import Vue, { PropOptions } from 'vue';
import { ToolbarMenuItem } from '@/api/models/home';
import { NeToolbar,
  NeToolbarLogo,
  NeToolbarMenu,
  NeToolbarMenuDropdown,
  NeToolbarMenuHamburger,
  NeIcon } from '@ne/ne-vue2-lib';
import ToolbarDropdown from './Dropdown/Dropdown.vue';

export default Vue.extend({
  name: 'Toolbar',
  components: {
    NeToolbar,
    NeToolbarLogo,
    NeToolbarMenu,
    NeToolbarMenuDropdown,
    NeToolbarMenuHamburger,
    ToolbarDropdown,
    NeIcon
  },
  props: {
    menu: {
      type: Array,
      required: true
    } as PropOptions<ToolbarMenuItem[]>
  },
  data () {
    return {
      activeMenuIndex: null as number | null,
      isExpandedOnMobile: false as boolean
    };
  },
  methods: {
    isMenuItemActive (index: number): boolean {
      return this.activeMenuIndex === index;
    },
    closeMenuItem (index: number) {
      setTimeout(() => {
        if (this.activeMenuIndex === index) {
          this.activeMenuIndex = null;
        }
      }, 100);
    },
    closeMenu (): void {
      this.activeMenuIndex = null;
      this.isExpandedOnMobile = false;
    }
  }
});
