










































import Vue, { PropOptions } from 'vue';
import { NeIcon } from '@ne/ne-vue2-lib';
import { ToolbarMenuItem } from '@/api/models/home';
import { Location } from 'vue-router';
import { RouteName } from '@/router/models';

export default Vue.extend({
  name: 'ToolbarDropdown',
  components: {
    NeIcon
  },
  props: {
    items: {
      type: Array,
      required: true
    } as PropOptions<ToolbarMenuItem[]>
  },
  computed: {
    activeRouteId (): string {
      return this.$route.params.id;
    }
  },
  methods: {
    resourceLinkUri (uri: string): string {
      return uri.substring(1).split('/').join(',');
    },
    getResourceRoute (uri: string): Location {
      return {
        name: RouteName.RESOURCE,
        params: {
          id: this.resourceLinkUri(uri)
        }
      };
    },
    goToResourcePage (uri: string): void {
      this.$router.push(this.getResourceRoute(uri));
      this.$emit('close');
    },
    isClassCategory (name: string): boolean {
      return name.toLowerCase().search('klasa') !== -1;
    }
  }
});
