






















































import Vue, { PropOptions } from 'vue';
import { NeTile, NeButton, NeIcon } from '@ne/ne-vue2-lib';
import { fileSize } from '@/shared/helpers/file-data';
import { RouteName } from '@/router/models';
import { Location } from 'vue-router';
import { URI_RESOURCES } from '@/api/endpoints';
import { GET_API_URL } from '@/store/getters';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'PublicationTile',
  components: {
    NeTile,
    NeButton,
    NeIcon
  },
  props: {
    active: {
      type: Boolean,
      required: true
    } as PropOptions<boolean>,
    level: {
      type: String,
      required: true
    } as PropOptions<string>,
    title: {
      type: String,
      required: true
    } as PropOptions<string>,
    coverUrl: {
      type: String,
      required: true
    } as PropOptions<string>,
    uri: {
      type: String,
      required: true
    } as PropOptions<string>,
    id: {
      type: Number,
      required: true
    } as PropOptions<number>,
    size: {
      type: Number,
      required: true
    } as PropOptions<number>
  },
  computed: {
    ...mapGetters({
      getApiUrl: GET_API_URL
    }),
    mainSectionTag () {
      return this.active ? 'router-link' : 'div';
    },
    downloadSize (): string {
      return fileSize(this.size);
    },
    resourceRoute (): Location {
      return {
        name: RouteName.RESOURCE,
        params: {
          id: this.uri.substring(1).split('/').join(',')
        }
      };
    },
    downloadLink (): string {
      const path = URI_RESOURCES(this.id);
      return this.getApiUrl(path);
    }
  }
});
