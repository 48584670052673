import Vuex from 'vuex';
import Vue from 'vue';
import state from './state';
import getters from './getters';
import { home } from './home';
import { RootState } from './models';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  state,
  getters,
  modules: {
    home
  }
});
