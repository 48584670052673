import { Api } from '@/api';

export interface EnvConfig {
  API_URL: string;
  PIWIK_ID: string;
  MNE_URL: string;
  ENV: string;
  SENTRY_DSN: string;
}

export const getApiConfig = async () => {
  const api = new Api({});
  const envConfig: EnvConfig = (await api.get<EnvConfig>('/env.json')).data;
  return envConfig;
};
